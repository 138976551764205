<template>
    <div>
        <div class="content">
            <el-form ref="cmdRealDataForm" label-width="150px">
                <el-row type="flex" class="row-gap" align="middle">
                    <el-col :span="6">
                        <el-tooltip class="item" effect="dark" placement="right-start"
                            content="注意：固件升级过程中，逆变器会停止供电，请谨慎操作" >
                            <span>Master版本<i class="el-icon-info"/></span>
                        </el-tooltip>
                    </el-col>
                    <el-col :span="6">
                        <span>当前版本 {{masterCurVersion}}</span>
                    </el-col>
                    <el-col v-if="masterCurVersion && isMasterNew" :span="6">
                        <span>新版本 {{masterLastVersion}}</span>
                    </el-col>
                    <el-col v-if="masterCurVersion && isMasterNew" :span="6">
                        <el-button @click="upgrade('Master')" type="primary" size="mini">升级</el-button>
                    </el-col>
                </el-row>
                <el-row type="flex" class="row-gap" align="middle">
                    <el-col :span="6">
                        <el-tooltip class="item" effect="dark" placement="right-start"
                            content="注意：固件升级过程中，逆变器会停止供电，请谨慎操作" >
                            <span>Slave版本<i class="el-icon-info"/></span>
                        </el-tooltip>
                    </el-col>
                    <el-col :span="6">
                        <span>当前版本 {{slaveCurVersion}}</span>
                    </el-col>
                    <el-col v-if="slaveCurVersion && isSlaveNew" :span="6">
                        <span>新版本 {{slaveLastVersion}}</span>
                    </el-col>
                    <el-col v-if="slaveCurVersion && isSlaveNew" :span="6">
                        <el-button @click="upgrade('Slave')" type="primary" size="mini">升级</el-button>
                    </el-col>
                </el-row>
                <el-row type="flex" class="row-gap" align="middle">
                    <el-col :span="6">
                        <el-tooltip class="item" effect="dark" placement="right-start"
                            content="注意：固件升级过程中，逆变器会停止供电，请谨慎操作">
                            <span>Comm版本<i class="el-icon-info"/></span>
                        </el-tooltip>
                    </el-col>
                    <el-col :span="6">
                        <span>当前版本 {{commCurVersion}}</span>
                    </el-col>
                    <el-col :span="6" v-if="commCurVersion && isCommNew">
                        <span >新版本 {{commLastVersion}}</span>
                    </el-col>
                    <el-col :span="6" v-if="commCurVersion && isCommNew">
                        <el-button @click="upgrade('Comm')" type="primary" size="mini">升级</el-button>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <el-dialog :visible.sync="isUpgrading" :close-on-click-modal="false" :append-to-body="true" center
               :close-on-press-escape="false" :show-close="false" width="280px" top="0" class="dialog" :title="upgradeTypeTitle">
            <div style="width: 100%; text-align: center">
                <el-progress type="circle" :percentage="upgradeInfo.curProgress" :stroke-width="20" :width="170"/>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import loading from '@/utils/cmdLoading';
import mqttService from '@/api/mqtt-service';
import message from '@/utils/message';
import global from '@/components/Global';
import {queryAiominiVersionInfo} from "@/api/esaio/device/esaioDeviceUpgradeApi";
import Global from "@/components/Global";

export default {

    name: 'device-aiomini-firmware-upgrade',
    data() {

        return {

            deviceSn: '',
            // 固件当前最新版本
            masterCurVersion: '',
            slaveCurVersion: '',
            commCurVersion: '',
            // 服务器最新的固件版本
            masterLastVersion: '',
            slaveLastVersion: '',
            commLastVersion: '',
            // 固件是否允许升级
            isMasterNew: false,
            isSlaveNew: false,
            isCommNew: false,
            // 升级进度信息
            upgradeInfo: {

                upgradeType: '',
                upgradeStatus: 0,
                totalDataPacketCount: 0,
                curDataPacketIndex: 0,
                curProgress: 0
            },
            upgradeInfoTitle: '固件升级',
            upgradeFailTimerId: null,
            upgradeFailTimerMs: 30,
            cmdLoading: null,
            upgradeTypeTitle: '固件升级中',
            isUpgrading: false,
            commandNameR: 'base_info'
        }
    },
    watch: {

        // 升级类型监听
        'upgradeInfo.upgradeType': {

            handler(newValue) {

                let upgradeType = newValue;
                if (upgradeType === 'Master') {

                    this.upgradeTypeTitle = 'Master版本升级中...';
                } else if (upgradeType === 'Slave') {

                    this.upgradeTypeTitle = 'Slave版本升级中...';
                } else if (upgradeType === 'Comm') {

                    this.upgradeTypeTitle = 'Comm版本升级中...';
                }
            }
        }
    },
    methods: {

        /**
         * 初始化方法.
         * 1、检查设备是否在升级中并打开MQTT连接（处于升级，将直接监听后台升级进度信息）
         * 2、非升级中或者设备离线：
         * (1)直接关闭MQTT连接
         * (2)下发指令，查询设备中的版本号
         * (3)查询服务器上已经发布的固件版本号
         */
        initPage(deviceSn) {

            this.deviceSn = deviceSn;
            this.cmdLoading = loading.loading('固件版本读取中...', 30, function () {

                mqttService.disconnectMqtt();
            });
            this.checkIsUpgrade()
                .then(() => this.sendGetVersionCommand())
                .then(() => this.getReleaseVersion())
                .then(() => {
                    this.cmdLoading.close();
            }).catch(() => {

                this.cmdLoading.close();
            })
        },
        /**
         * 检查设备是否在升级中.
         */
        checkIsUpgrade() {

            return new Promise((resolve, reject) => {

                let option = {

                    server : global.esaioServerBase,
                    url: '/aioMiniUpgrade/checkIsUpgrade',
                    data: {

                        deviceNum: this.deviceSn,
                    },
                    isFilterMessageId: false,
                    isAutoDisconnectMqtt: false,
                    topic: global.mqttDeviceCmdTopicPrefix + 'upgrade/' + this.deviceSn,
                    httpSuccessCallback: data => {

                        Object.assign(this.upgradeInfo, data.data);
                        if (this.upgradeInfo.upgradeType === '') {

                            // 不在升级中，先断开MQTT链接
                            mqttService.disconnectMqtt();
                            // 下发查询设备版本的指令，备注：在这里下发指令的原因是：如果设备在升级中，这个指令是下发不了的
                            resolve();
                        } else {

                            reject();
                            // 设备升级中，打开弹窗
                            this.isUpgrading = true;
                            this.openUpgradeProcess();
                            this.curDataPacketIndexChange();
                        }
                    },
                    httpFailCallback: data => {

                        reject();
                        console.log(data);
                        message.error(data.msg);
                        mqttService.disconnectMqtt();
                    },
                    mqttMessageCallback: (message) => {

                        reject();
                        if (message.code === Global.response_status_success_obj) {

                            Object.assign(this.upgradeInfo, message.data);
                            // 渲染设备升级进度
                            this.curDataPacketIndexChange();
                        } else {

                            mqttService.disconnectMqtt();
                        }
                    }
                }
                mqttService.mqttRequest(option);
            })
        },
        /**
         * 下发指令读取设备中的版本号
         */
        sendGetVersionCommand() {

            return new Promise((resolve, reject) => {

                let option = {

                    data: {

                        deviceSn: this.deviceSn,
                        commandCode: this.commandNameR
                    },
                    httpFailCallback: (data) => {

                        console.log('获取设备版本http请求失败: ' + data.msg);
                        reject();
                        message.error(data.msg);
                    },
                    mqttMessageCallback: (res) => {

                        if (res.code !== Global.response_status_success_obj) {

                            reject();
                            message.error('请求失败');
                        } else {

                            let data = res.data;
                            this.masterCurVersion = data.masterVersion;
                            this.slaveCurVersion = data.slaveVersion;
                            this.commCurVersion = data.commVersion;
                            // 获取发布的版本
                            resolve();
                        }
                    }
                }
                mqttService.sendEsaioBusinessCommand(option);
            })
        },
        /**
         * 获取服务器上已经发布的版本信息.
         */
        getReleaseVersion() {

            return new Promise((resolve, reject) => {

                let param = {

                    sn: this.deviceSn
                }
                queryAiominiVersionInfo(param).then(res => {

                    if (res.code === Global.response_status_success_obj) {

                        this.masterLastVersion = res.data.dspLastVersion;
                        this.slaveLastVersion = res.data.cpuLastVersion;
                        this.commLastVersion = res.data.armLastVersion;
                        this.isMasterNew = this.masterCurVersion && this.masterLastVersion && (this.masterCurVersion !== this.masterLastVersion);
                        this.isSlaveNew = this.slaveCurVersion && this.slaveLastVersion && (this.slaveCurVersion !== this.slaveLastVersion);
                        this.isCommNew = this.commCurVersion && this.commLastVersion && (this.commCurVersion !== this.commLastVersion);
                        resolve();
                    } else {

                        reject();
                        message.error(res.msg);
                    }
                }).catch(err => {

                    reject();
                    message.error('请求失败,请稍后再试');
                    console.error(err);
                });
            });
        },
        upgrade(type) {

            let that = this;
            this.cmdLoading = loading.loading('检查版本...', 30, function () {

                mqttService.disconnectMqtt();
            });
            // 重新查询版本号
            this.sendGetVersionCommand()
                .then(() => this.getReleaseVersion())
                .then(() => {

                    that.cmdLoading.close();
                    // 检测版本是否可升级
                    let content = '';
                    let allowUpgrade = false;
                    if (type === 'Master' && that.isMasterNew) {

                        content = '确认升级Master版本？';
                        allowUpgrade = true;
                    } else if (type === 'Slave' && that.isSlaveNew) {

                        content = '确认升级Slave版本？';
                        allowUpgrade = true;
                    } else if (type === 'Comm' && that.isCommNew) {

                        content = '确认升级Comm版本？';
                        allowUpgrade = true;
                    }
                    if (!allowUpgrade) {

                        // 已经是最新版本，不需要升级
                        message.warning('已经是最新版本')
                        return;
                    }
                    // 可升级，询问用户是否确认升级
                    message.confirm(content, false, 'warning', ()=>{

                        this.cmdLoading = loading.loading('下发启动升级指令...', 30, function () {

                            mqttService.disconnectMqtt();
                        });
                        // 下发启动升级指令
                        let option = {

                            server : global.esaioServerBase,
                            url: '/aioMiniUpgrade/upgrade',
                            isFilterMessageId: false,
                            isAutoDisconnectMqtt: false,
                            topic: global.mqttDeviceCmdTopicPrefix + 'upgrade/' + that.deviceSn,
                            data: {

                                deviceNum: that.deviceSn,
                                type: type
                            },
                            httpFailCallback: data => {

                                that.cmdLoading.close();
                                console.log(data)
                                message.error('启动升级失败');
                                mqttService.disconnectMqtt();
                            },
                            mqttMessageCallback: (res) => {

                                // 关掉指令下发加载圈
                                that.cmdLoading.close();
                                if (res.code === 200) {

                                    Object.assign(that.upgradeInfo, res.data);
                                    // 启动指令回复成功或收到设备索要数据包信息后，渲染升级进度信息
                                    if (!that.isUpgrading) {

                                        that.isUpgrading = true;
                                        // 第一次收到升级返回消息，打开进度框
                                        that.openUpgradeProcess();
                                    }
                                    that.curDataPacketIndexChange();
                                } else {

                                    mqttService.disconnectMqtt();
                                }
                            }
                        }
                        mqttService.mqttRequest(option);
                    })
                }).catch(() => {

                    that.cmdLoading.close();
                })
        },
        openUpgradeProcess() {

            let that = this;
            this.upgradeFailTimerId = setInterval(() => {

                if (that.upgradeFailTimerMs === 0) {

                    that.isUpgrading = false;
                    mqttService.disconnectMqtt();
                    that.clearUpgradeFailTimer();
                    message.error('升级失败，请稍后重试');
                } else {

                    that.upgradeFailTimerMs = that.upgradeFailTimerMs - 1;
                }
            }, 1000);
        },
        // 当前升级包序号发生变化, 更新预计剩余时间和进度
        curDataPacketIndexChange() {

            let curDataPacketIndex = this.upgradeInfo.curDataPacketIndex;
            let totalDataPacketCount = this.upgradeInfo.totalDataPacketCount;
            // 0：未开始升级 1：升级中 2：升级成功 3：升级失败
            let upgradeStatus = this.upgradeInfo.upgradeStatus;
            if (totalDataPacketCount > 0 && curDataPacketIndex === totalDataPacketCount && upgradeStatus === 2) {

                // 升级成功清掉计时器
                this.clearUpgradeFailTimer();
                this.upgradeInfo.curProgress = 100;
                // 关闭升级进度框
                this.isUpgrading = false;
                // 告诉用户升级成功
                message.success('升级成功，待设备重启后，再刷新此页面')
                // 断开MQTT连接
                mqttService.disconnectMqtt();
            } else {

                this.upgradeFailTimerMs = 30;
                this.upgradeInfo.curProgress = Math.floor(curDataPacketIndex / totalDataPacketCount * 100);
            }
        },
        clearUpgradeFailTimer() {

            if (this.upgradeFailTimerId) {

                clearInterval(this.upgradeFailTimerId);
            }
            this.upgradeFailTimerId = null;
        }
    }
}
</script>

<style lang="scss" scoped>

.content {

    margin: 10px 0 1% 5%;
    .row-gap {

        margin-bottom: 30px;
        width: 600px;
    }
}
</style>

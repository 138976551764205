import axios from 'axios';
import global from '../../components/Global.vue';

let base = global.serverSite + '/doartIot/doart-modbus';

// 添加寄存器信息
export const addCommandInfo = params => { return axios.post(base + '/commandInfo/add', params)};

// 编辑寄存器信息
export const editCommandInfo = params => { return axios.post(base + '/commandInfo/edit', params)};

// 查询MODBUS指令信息详情
export const queryCommandInfoDetail = params => { return axios.post(base + '/commandInfo/detail', params)};

// 删除指令信息
export const deleteCommandInfo = params => { return axios.post(base + '/commandInfo/delete', params)};

// 根据产品型号id查询所有写指令信息
export const queryAllWriteCommandInfoList = params => { return axios.post(base + '/commandInfo/queryAllWriteCommandInfoList', params)};

// 根据点表id查询所有读指令信息
export const queryAllReadCommandInfoList = params => { return axios.post(base + '/commandInfo/queryAllReadCommandInfoList', params)};

// 根据指令id查询指令下发页面的元素
export const queryModbusCommandPageElement = params => { return axios.post(base + '/commandInfo/queryModbusCommandPageElement', params)};

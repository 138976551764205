<template>
    <el-dialog title="储能设备指令控制" :visible.sync="deviceModbusCmdPageVisible" width="1200px" top="15vh"
               id="modbusDeviceCmdIndexDialog" :close-on-press-escape="false" custom-class="cmd-dialog-style"
               @close="closeModbusCmdPage" :close-on-click-modal="false" :destroy-on-close=true>
        <div class="modbus-cmd-main">
            <div style="width: 25%;">
                <div class="command-list">
                    <div v-for="(tab) in readCommandVoList">
                        <div :class="{'command-tab': true, 'tab-is-active': currentCommand.commandName === tab.commandName}"
                             :ref="tab.commandName" @click="commandTabChange(tab)">{{tab.commandNameCn}}</div>
                    </div>
                </div>
            </div>
            <div style="width: 75%;">
                <div class="command-content" v-if="isUpgradeContent">
                    <!-- 其他modbus通讯设备，升级页面再此添加，ref命名为：device + productModelCode + FirmwareUpgrade -->
                    <div v-if="productModelCode === 'AioMini'">
                        <device-aiomini-firmware-upgrade ref="deviceAioMiniFirmwareUpgrade"/>
                    </div>
                </div>
                <div class="command-content" v-else>
                    <div>
                        <el-form :model="cmdRealDataForm" ref="cmdRealDataForm" label-width="150px" :inline="true">
                            <el-row v-if="switchItemList && switchItemList.length > 0" v-for="(item, index) in switchItemList">
                                <el-col :span="12" v-if="index * 2 < switchItemList.length">
                                    <el-form-item :key="switchItemList[index * 2].enumCn" :label="switchItemList[index * 2].enumCn + ':'">
                                        <el-switch v-model="switchItemList[index * 2].value" style="margin-left: 160px"/>
                                        <el-popover placement="top" trigger="click">
                                            <div v-html="switchItemList[index * 2].remark"/>
                                            <i slot="reference" class="el-icon-info field_tip_icon"/>
                                        </el-popover>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12" v-if="index * 2 + 1 < switchItemList.length">
                                    <el-form-item :key="switchItemList[index * 2 + 1].enumCn" :label="switchItemList[index * 2 + 1].enumCn + ':'">
                                        <el-switch v-model="switchItemList[index * 2 + 1].value" style="margin-left: 160px"/>
                                        <el-popover placement="top" trigger="click">
                                            <div v-html="switchItemList[index * 2 + 1].remark"/>
                                            <i slot="reference" class="el-icon-info field_tip_icon"/>
                                        </el-popover>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row v-if="selectItemList && selectItemList.length > 0" v-for="(item, index) in selectItemList">
                                <el-col :span="12" v-if="index * 2 < selectItemList.length">
                                    <el-form-item :key="selectItemList[index * 2].fieldCn" :label="selectItemList[index * 2].fieldCn + ':'">
                                        <el-select :class="selectItemList[index * 2].error ? 'is-error': ''" v-model="selectItemList[index * 2].value" style="width: 200px">
                                            <el-option v-for="selectItem in selectItemList[index * 2].selectList" :key="selectItem.value" :label="selectItem.label" :value="selectItem.value">
                                            </el-option>
                                        </el-select>
                                        <el-popover placement="top" trigger="click">
                                            <div v-html="selectItemList[index * 2].remark"/>
                                            <i slot="reference" class="el-icon-info field_tip_icon"/>
                                        </el-popover>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12" v-if="index * 2 + 1 < selectItemList.length">
                                    <el-form-item :key="selectItemList[index * 2 + 1].fieldCn" :label="selectItemList[index * 2 + 1].fieldCn + ':'">
                                        <el-select :class="selectItemList[index * 2 + 1].error ? 'is-error': ''" v-model="selectItemList[index * 2 + 1].value" style="width: 200px">
                                            <el-option v-for="selectItem in selectItemList[index * 2 + 1].selectList" :key="selectItem.value" :label="selectItem.label" :value="selectItem.value">
                                            </el-option>
                                        </el-select>
                                        <el-popover placement="top" trigger="click">
                                            <div v-html="selectItemList[index * 2 + 1].remark"/>
                                            <i slot="reference" class="el-icon-info field_tip_icon"/>
                                        </el-popover>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row v-if="inputItemList && inputItemList.length > 0" v-for="(item, index) in inputItemList">
                                <el-col :span="12" v-if="index * 2 < inputItemList.length">
                                    <el-form-item :key="inputItemList[index * 2].fieldCn" :label="inputItemList[index * 2].fieldCn + ':'">
                                        <el-input :class="inputItemList[index * 2].error ? 'is-error': ''" v-model="inputItemList[index * 2].value"
                                                  :placeholder="inputItemList[index * 2].errorMessage" style="width: 200px">
                                            <template v-if="inputItemList[index * 2].unit !== null && inputItemList[index * 2].unit !== ''"
                                                      slot="append">{{inputItemList[index * 2].unit}}</template>
                                        </el-input>
                                        <el-popover placement="top" trigger="click">
                                            <div v-html="inputItemList[index * 2].remark"/>
                                            <i slot="reference" class="el-icon-info field_tip_icon"/>
                                        </el-popover>
                                        <div v-show="inputItemList[index * 2].error" class="el-form-item__error">{{inputItemList[index * 2].errorMessage}}</div>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12" v-if="index * 2 + 1 < inputItemList.length">
                                    <el-form-item :key="inputItemList[index * 2 + 1].fieldCn" :label="inputItemList[index * 2 + 1].fieldCn + ':'">
                                        <el-input :class="inputItemList[index * 2 + 1].error ? 'is-error' : ''" v-model="inputItemList[index * 2 + 1].value"
                                                  :placeholder="inputItemList[index * 2 + 1].errorMessage" style="width: 200px">
                                            <template v-if="inputItemList[index * 2 + 1].unit !== null && inputItemList[index * 2 + 1].unit !== ''"
                                                      slot="append">{{inputItemList[index * 2 + 1].unit}}</template>
                                        </el-input>
                                        <el-popover placement="top" trigger="click">
                                            <div v-html="inputItemList[index * 2 + 1].remark"/>
                                            <i slot="reference" class="el-icon-info field_tip_icon"/>
                                        </el-popover>
                                        <div v-show="inputItemList[index * 2 + 1].error" class="el-form-item__error">{{inputItemList[index * 2 + 1].errorMessage}}</div>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                    </div>
                    <el-button class="button" type="primary" :disabled="disableSetButton" @click="sendWriteCmd">设置</el-button>
                </div>
            </div>
        </div>
    </el-dialog>
</template>
<script>

    import {queryAllWriteCommandInfoList, queryModbusCommandPageElement} from '@/api/modbus/modbusCommand';
    import mqttService from '@/api/mqtt-service';
    import message from '@/utils/message';
    import loading from '@/utils/cmdLoading';
    import jsonSchema from '@/api/jsonschema/json-schema.js';
    import {queryModbusCommandJsonSchemaDetail} from '@/api/jsonschema/jsonSchemaApi';
    import Global from '@/components/Global';
    import DeviceAiominiFirmwareUpgrade from "@/views/esaio/device/device-aiomini-cmd/device-aiomini-firmware-upgrade.vue";

    export default {

        name: "device-modbus-cmd",
        components: {DeviceAiominiFirmwareUpgrade},
        props: {

            deviceInfo: {

                type: Object
            }
        },
        data() {

            return {

                productModelCode: '',
                serialNumber: '',
                isUpgradeContent: false,
                deviceModbusCmdPageVisible: false,
                activeName: '',
                currentCommand: {},
                readCommandVoList: [],
                // switch集合
                switchItemList: [],
                // 输入框集合
                inputItemList: [],
                // 选择框集合
                selectItemList: [],
                // 指令实时数据
                cmdRealDataForm: {},
                disableSetButton: true,
                currentCommandTabRef: null,
                currentJsonSchema: {}
            }
        },
        methods: {

            initDeviceModbusCmdPage(productModelCode, serialNumber) {

                this.productModelCode = productModelCode;
                this.serialNumber = serialNumber;
                this.deviceModbusCmdPageVisible = true;
                this.readCommandVoList = [];
                this.$nextTick(() => {

                    this.queryAllWriteCommandInfoList();
                });
            },
            queryAllWriteCommandInfoList() {

                let param = {

                    id: this.deviceInfo.productModelId
                };
                // 查询写指令集合填充tab
                queryAllWriteCommandInfoList(param).then((res) => {

                    if (res.code === Global.response_status_success_obj) {

                        res.data.forEach(item => {

                            this.readCommandVoList.push({

                                commandNameCn: item.commandNameCn,
                                commandName: item.commandName,
                                commandId: item.commandId,
                                readCommandName: item.readCommandName,
                                productModelCode: item.productModelCode
                            });
                        });
                        // 添加固件信息相关指令信息
                        this.readCommandVoList.push({

                            commandNameCn: '固件升级',
                            commandName: 'upgrade',
                            productModelCode: this.productModelCode,
                            serialNumber: this.serialNumber
                        });
                        // 初始默认选中
                        let firstCommand = res.data[0];
                        this.activeName = firstCommand.commandName;
                        this.commandTabChange(firstCommand);
                    } else {

                        message.error(res.msg);
                    }
                }).catch(err => {

                    message.error('请求失败,请稍后再试');
                    console.error(err);
                });
            },
            // 处理标签页点击事件.
            commandTabChange(command) {

                this.currentCommand = command;
                // 定制化升级指令
                if ('upgrade' === command.commandName) {

                    this.isUpgradeContent = true;
                    this.$nextTick(() => {

                        this.$refs['device' + this.productModelCode + 'FirmwareUpgrade'].initPage(this.serialNumber);
                    });
                    return;
                }
                // 其他modbus指令
                this.isUpgradeContent = false;
                this.queryCommandPageElement(command);
            },
            // 查询指令下寄存器
            queryCommandPageElement(command) {

                let param = {

                    id: command.commandId
                };
                queryModbusCommandPageElement(param).then((res) => {

                    if (res.code === Global.response_status_success_obj) {

                        res.data.inputItemList.forEach(item => {

                            item.error = false;
                            item.errorMessage = '';
                            item.remark = item.remark.replaceAll(/\n/g, "<br/>");
                        });
                        this.inputItemList = res.data.inputItemList;
                        res.data.switchItemList.forEach(item => {

                            item.remark = item.remark.replaceAll(/\n/g, "<br/>");
                        });
                        this.switchItemList = res.data.switchItemList;
                        res.data.selectItemList.forEach(item => {

                           item.error = false;
                           item.errorMessage = '';
                           item.remark = item.remark.replaceAll(/\n/g, "<br/>");
                           // 给下拉框一个默认值
                            if (item.selectList && item.selectList.length > 0) {

                                item.value = item.selectList[0].value;
                            }
                        });
                        this.selectItemList = res.data.selectItemList;
                        this.queryWriteCommandJsonSchema(command.productModelCode, command.commandName);
                        this.sendReadCmd();
                    } else {

                        message.error(res.msg);
                    }
                }).catch(err => {

                    message.error('请求失败,请稍后再试');
                    console.error(err);
                });
            },
            // 查询写指令的的json schema校验信息
            queryWriteCommandJsonSchema(productModelCode, commandName) {

                this.currentJsonSchema = {};
                let param = {

                    productModelCode: productModelCode,
                    commandCode: commandName
                };
                queryModbusCommandJsonSchemaDetail(param).then((res) => {

                    if (res.code === Global.response_status_success_obj) {

                        this.currentJsonSchema = res.data;
                        if (!this.currentJsonSchema) {

                            return;
                        }
                        // 给输入框 下拉框 的提示信息赋值
                        let properties = this.currentJsonSchema.properties;
                        for (let key in properties) {

                            if (!properties[key].errorMessage) {

                                continue;
                            }
                            let inputItem = this.inputItemList.find(inputItem => inputItem.fieldEn === key);
                            if (!inputItem) {

                                continue;
                            }
                            inputItem.errorMessage = properties[key].errorMessage;
                            let selectItem = this.selectItemList.find(selectItem => selectItem.fieldEn === key);
                            if (!selectItem) {

                                continue;
                            }
                            selectItem.errorMessage = properties[key].errorMessage;
                        }
                    } else {

                        message.error(res.msg);
                    }
                }).catch(err => {

                    message.error('请求失败,请稍后再试');
                    console.error(err);
                });
            },
            // 下发读指令
            sendReadCmd() {

                let that = this;
                // 只有写指令配置了读指令才下发读指令
                if (that.currentCommand.readCommandName === null || that.currentCommand.readCommandName === '') {

                    that.disableSetButton = false;
                    return;
                }
                let loading = that.openLoading("参数读取中...");
                let option = {

                    data: {

                        deviceSn: this.deviceInfo.serialNumber,
                        commandCode: this.currentCommand.readCommandName
                    },
                    httpFailCallback: function (data) {

                        if (loading) {

                            loading.close();
                        }
                        message.error(data.msg);
                    },
                    mqttMessageCallback: function (msg, topic) {

                        if (loading) {

                            loading.close();
                        }
                        that.disableSetButton = false;
                        console.log("mqtt消息回调通知：" + msg)
                        if (msg.code === Global.response_status_success_obj) {

                            that.renderingDynamicForm(msg.data);
                        } else {

                            message.error("指令读取失败");
                        }
                    }
                }
                mqttService.sendEsaioBusinessCommand(option);
            },
            // 发送写指令
            sendWriteCmd() {

                let that = this;
                let param = this.getWriteCmdParam();
                // 校验参数
                let check = jsonSchema.validate(this.currentJsonSchema, param);
                if (check.pass) {

                    let loading = that.openLoading("参数设置中...");
                    let option = {

                        data: {

                            'deviceSn': this.deviceInfo.serialNumber,
                            'commandCode': this.currentCommand.commandName,
                            'data': param
                        },
                        httpFailCallback: function(data) {

                            if (loading) {

                                loading.close();
                            }
                            message.error(data.msg);
                            if (data.data !== null && data.data !== '') {

                                data.data.forEach(fieldEn => {

                                    let inputItem = that.inputItemList.find(inputItem => inputItem.fieldEn === fieldEn);
                                    if (inputItem) {

                                        inputItem.error = true;
                                    }
                                    let selectItem = that.selectItemList.find(selectItem => selectItem.fieldEn === fieldEn);
                                    if (selectItem) {

                                        selectItem.error = true;
                                    }
                                })
                            }
                        },
                        mqttMessageCallback: function(msg, topic) {

                            console.log("mqtt消息回调通知：" + msg);
                            if (loading) {

                                loading.close();
                            }
                            if (msg.code === Global.response_status_success_obj) {

                                // 写指令执行成功
                                message.success("参数设置成功");
                            } else {

                                // 写指令执行失败
                                message.error("参数设置失败");
                            }
                        }
                    }
                    mqttService.sendEsaioBusinessCommand(option);
                } else {

                    console.log("check:" + JSON.stringify(check));
                    check.rejectItemList.forEach(fieldEn => {

                        let inputItem = that.inputItemList.find(inputItem => inputItem.fieldEn === fieldEn);
                        if (inputItem) {

                            inputItem.error = true;
                        }
                        let selectItem = that.selectItemList.find(selectItem => selectItem.fieldEn === fieldEn);
                        if (selectItem) {

                            selectItem.error = true;
                        }
                    });
                }
            },
            getWriteCmdParam() {

                let param = {};
                // 输入框
                this.inputItemList.forEach(item => {

                    if (item.value === '') {

                        param[item.fieldEn] = null;
                    } else if (item.analysisMode === 1 || item.analysisMode === 2) {

                        param[item.fieldEn] = Number(item.value);
                    } else {

                        param[item.fieldEn] = item.value;
                    }
                    item.error = false;
                });
                // switch开关
                this.switchItemList.forEach(item => {

                    let bitIndex = item.addressLength * 8 - 1;
                    let triggerVal = item.value ? item.triggerVal : 1 - item.triggerVal;
                    let result = item.registerValue;
                    if (result === undefined) {

                        param[item.fieldEn] = null;
                        return;
                    }
                    if (param[item.fieldEn] && param[item.fieldEn] !== null && param[item.fieldEn] !== '') {

                        result = param[item.fieldEn];
                    }
                    if (item.bit === 0) {

                        result = result.substring(0, bitIndex - item.bit) + triggerVal;
                    } else if (bitIndex === item.bit) {

                        result = triggerVal + result.substring(bitIndex - item.bit);
                    } else {

                        result = result.substring(0, bitIndex - item.bit) + triggerVal + result.substring(bitIndex + 1 - item.bit);
                    }
                    param[item.fieldEn] = result;
                });
                // 下拉框
                this.selectItemList.forEach(item => {

                    param[item.fieldEn] = Number(item.value);
                    item.error = false;
                });
                return param;
            },
            // 渲染动态表单
            renderingDynamicForm(data) {

                // 渲染input
                this.inputItemList.forEach(inputItem => {

                    inputItem.value = data[inputItem.fieldEn];
                });
                // 渲染switch
                this.switchItemList.forEach(switchItem => {

                    switchItem.value = data[switchItem.fieldEn].charAt(switchItem.addressLength * 8 - 1 - switchItem.bit) === switchItem.triggerVal;
                    switchItem.registerValue = data[switchItem.fieldEn];
                });
                // 渲染select
                this.selectItemList.forEach(selectItem => {

                    selectItem.value = data[selectItem.fieldEn];
                })
            },
            // 开启全屏遮罩
            openLoading(msg) {

                return loading.loading(msg, 30);
            },
            closeModbusCmdPage() {

                this.deviceModbusCmdPageVisible = false;
                this.$emit('queryEsaioDeviceInfoList');
            },
        }
    }
</script>
<style lang="scss" scoped>

    .modbus-cmd-main {

        display: flex;
    }

    .command-list {

        overflow-y: auto;
        height: 500px;
        border-radius: 4px;
        background-color: #fff;
    }

    .command-tab {

        padding: 0 20px;
        height: 40px;
        box-sizing: border-box;
        line-height: 40px;
        display: inline-block;
        list-style: none;
        font-size: 14px;
        font-weight: 500;
        color: #303133;
        position: relative;
    }

    .command-tab:hover {

        color: #409EFF;
        cursor: pointer;
    }

    .tab-is-active {

        color: #409EFF;
    }

    .command-list::-webkit-scrollbar {

        width: 3px;
    }

    .command-list::-webkit-scrollbar-thumb {

        background: #8798AF;
        border-radius: 2px;
    }

    .command-list::-webkit-scrollbar-track {

        background: transparent;
    }

    .button {

        margin-left: 45%;
    }

    .command-content {

        overflow: auto;
        height: 500px;
        border-radius: 4px;
        background-color: #fff;
        margin-top: 20px;
    }

    .command-content::-webkit-scrollbar {

        width: 3px;
    }

    .command-content::-webkit-scrollbar-thumb {

        background: #8798AF;
        border-radius: 2px;
    }

    .command-content::-webkit-scrollbar-track {

        background: transparent;
    }

    .field_tip_icon {

        font-size: 20px;
        vertical-align: middle;
        margin-left: 5px;
    }

    .is-error input[type=text] {

        border-color: #F56C6C;
    }

    .cmd-dialog-style {

        width: 1070px;
        height: 660px;
    }
</style>
